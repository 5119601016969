import {
  BatchTransfer,
  ExternalBatchTransfer,
  TRANSFER_TYPES
} from "@/interfaces/batchTransferManager";
import { store } from "@/internal";
import InboundTransferMapper from "./InboundTransfer.mapper";
import NewBatchTransferMapper from "./NewBatch.mapper";
import OutboundTransferMapper from "./OutboundTransfer.mapper";

export class InvoicesMapper {
  protected inboundMapper: InboundTransferMapper = new InboundTransferMapper();
  protected outboundMapper: OutboundTransferMapper = new OutboundTransferMapper();
  protected newBatchMapper: NewBatchTransferMapper = new NewBatchTransferMapper();

  public mapInput(
    invoice: ExternalBatchTransfer.Input.Invoices.Invoice
  ): BatchTransfer.Invoice {
    const transfer = this.getInvoiceTransfer(invoice.transfer);

    return {
      id: invoice.id,
      transfer_id: invoice.transfer_id,
      barcode: invoice.barcode,
      transfer,
      payments: invoice.payments
        ? invoice.payments.map(payment => ({
            type: payment.payment_method_id,
            applied: payment.amount,
            paymentMethod:
              (payment.payment_method && payment.payment_method.name) || "",
            remaining: 0,
            created_at: payment.created_at,
            updated_at: payment.upadted_at,
            id: payment.id
          }))
        : [],
      updated_at: invoice.updated_at,
      created_at: invoice.created_at,
      total: invoice.total,
      outstanding: invoice.outstanding,
      status: invoice.status
    };
  }

  protected getInvoiceTransfer(
    transfer: ExternalBatchTransfer.Transfer
  ): BatchTransfer.Transfer {
    const mappers: {
      [key: string]: (
        transfer: ExternalBatchTransfer.Transfer
      ) => BatchTransfer.Transfer;
    } = {
      INBOUND: t =>
        this.inboundMapper.mapInput(
          t as ExternalBatchTransfer.Input.InboundTransfer.Inbound
        ),
      OUTBOUND: t =>
        this.outboundMapper.mapInput(
          t as ExternalBatchTransfer.Input.OutboundTransfer.Outbound
        ),
      NEW_BATCH: t =>
        this.newBatchMapper.mapInput(
          t as ExternalBatchTransfer.Input.NewBatchTransfer.NewBatch
        )
    };
    const currentLocation = store.getters["AuthModule/currentLocation"];

    const type =
      transfer.type === TRANSFER_TYPES.INBOUND &&
      transfer.sourceable_id === currentLocation.id
        ? "NEW_BATCH"
        : transfer.type;
    return mappers[type]!(transfer);
  }
}
