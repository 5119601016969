import { InvoicesMapper } from "@/components/inventory/BatchTransfer/EntityMappers/Invoices.mapper";
import {
  BatchTransfer,
  ExternalBatchTransfer
} from "@/interfaces/batchTransferManager";
import HttpService from "@/services/http.service";
import { AxiosResponse } from "axios";
import { TablePagination } from "helix-vue-components";
import Vue from "vue";

export class InvoiceService extends HttpService {
  protected mapper: InvoicesMapper = new InvoicesMapper();
  public async getAll(params: { [key: string]: any } = {}): Promise<any> {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "GET",
      url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers/invoices`,
      params
    });
    const pagination: TablePagination = {
      itemsPerPage: response.data.data.per_page,
      totalItems: response.data.data.total,
      currentPage: response.data.data.current_page,
      itemsPerPageOptions: [5, 10, 20, 50],
      from: response.data.data.from,
      to: response.data.data.to
    };

    return {
      data: response.data.data.data.map(
        (invoice: ExternalBatchTransfer.Input.Invoices.Invoice) =>
          this.mapper.mapInput(invoice)
      ),
      pagination
    };
  }
  public async getById(
    transferId: number,
    id: number,
    params?: { [key: string]: any }
  ) {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "GET",
      url: `${
        process.env.VUE_APP_BASE_URL
      }/inventory/transfers/${transferId}/invoices/${id}`,
      params: {
        embed: "payments",
        ...params
      }
    });
    return this.mapper.mapInput(response.data.data);
  }

  public async addPayments(
    invoice: BatchTransfer.Invoice,
    paymentsBreakdown: BatchTransfer.PaymentMethodsBreakdown
  ) {
    const response: AxiosResponse<any> = await Vue.axios({
      method: "POST",
      url: `${process.env.VUE_APP_BASE_URL}/inventory/transfers/${
        invoice.transfer_id
      }/invoices/${invoice.id}/payments/create_collection`,
      data: {
        collection: paymentsBreakdown.payment_and_methods.map(p => ({
          payment_method_id: p.type,
          amount: p.applied
        }))
      }
    });
    return response;
  }
}

export default new InvoiceService();
