import TransferValidators from "@/components/inventory/BatchTransfer/BatchTransferManager/TransferValidators.mixin";
import PaymentBreakdown from "@/components/inventory/BatchTransfer/BuildBlocks/PaymentBreakdown/PaymentBreakdown.component";
import { BatchTransfer } from "@/interfaces/batchTransferManager";
import { InvoiceService } from "@/services/BatchTransferManager/Invoice.service";
import { messagesService } from "@/services/messages.service";
import { Validator } from "vee-validate";
import { Component, Inject, Mixins, Provide } from "vue-property-decorator";
import WriteOffModal from "../WriteOffModal/WriteOffModal.component";
import Template from "./AddPayment.template.vue";

@Component({
  mixins: [Template],
  components: {
    "payments-breakdown": PaymentBreakdown
  }
})
export default class InvoiceAddPayment extends Mixins(TransferValidators) {
  @Inject() public $validator!: Validator;
  @Provide() public validator: Validator = this.$validator;

  public loading: boolean = false;
  public breakdown: BatchTransfer.PaymentMethodsBreakdown = {
    initial: 0,
    payment_and_methods: [],
    outstanding: 0
  };

  protected service: InvoiceService = new InvoiceService();
  protected model: BatchTransfer.Invoice | null = null;

  public updateBreakdown(breakdown: BatchTransfer.PaymentMethodsBreakdown) {
    this.breakdown = breakdown;
  }
  public async payInvoice() {
    await this.service.addPayments(this.model!, this.breakdown);
    this.$router.push({
      name: "batch-transfer",
      params: { type: "invoice" }
    });
  }

  public async writeOffConfirm() {
    const confirm = await this.$modals.load<boolean>(WriteOffModal, {
      size: "normal"
    });

    if (confirm) {
      messagesService.showMessage(
        "fal fa-exclamation-triangle",
        "Method Not Implemented",
        "warning"
      );
    }
  }
  protected mounted() {
    this.initializeData();
  }

  protected async initializeData() {
    const invoice = await this.service.getById(
      Number(this.$route.params.transfer_id),
      Number(this.$route.params.invoice_id)
    );
    this.model = invoice;
    this.breakdown.initial = invoice.outstanding;
  }
}
